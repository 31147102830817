import api from "../httpClient.js";

export async function getClientes(filtros) {
  const response = await api.get(`/clientes${filtros}`);
  return response.data.clientes;
}

export async function getListClientes(filtros) {
  const response = await api.post(`/listaclientes${filtros}`);
  return response.data.cliente;
}

// Busca um cliente
export async function getCliente(id) {
  const response = await api.get(`/clientes/${id}`);
  return response.data.cliente;
}

// Busca um cliente ADM
export async function getClienteAdm(id) {
  const response = await api.get(`/clientes-adm/${id}`);
  return response.data.cliente;
}

// Busca um cliente ADM OBS
export async function getClienteAdmObs(id) {
  const response = await api.get(`/clientes-adm-obs/${id}`);
  return response.data.cliente_obs;
}

// Busca veiculos do cliente
export async function getVeiculos(id) {
  const response = await api.get(`/clientes/${id}/veiculos`);
  return response.data.cliente;
}

// Busca contas do cliente
export async function getContas(id) {
  const response = await api.get(`/clientes/${id}/contas`);
  return response.data.cliente;
}

// Busca contas do cliente
export async function getObservacoes(id) {
  const response = await api.get(`/clientes/${id}/observacoes`);
  return response.data.cliente;
}

// Atualiza uma observacao existente
export function putObservacao(id, obs) {
  return api.put(`/clienteobs/${id}`, {
    data: obs.data,
    obs: obs.obs,
  });
}

// Busca contatos do cliente
export async function getContatos(id) {
  const response = await api.get(`/clientes/${id}/contatos`);
  return response.data.cliente;
}

// Busca contatos do cliente
export async function getContato(id) {
  const response = await api.get(`/contatos/${id}`);
  return response.data.contato;
}

// Busca contatos do cliente
export function putContato(id, contato) {
  let body = {};
  for (let key in contato) {
    body[key] = contato[key];
  }
  return api.put(`/contatos/${id}`, body);
}

// Cria um contato novo
export function postContato(contato) {
  let body = {};
  for (let key in contato) {
    body[key] = contato[key];
  }
  return api.post("/contatos/add", body);
}

// Atualiza um cliente existente
export function putCliente(id, cliente) {
  let body = {};
  for (let key in cliente) {
    if (key != "numero_veiculos") {
      body[key] = cliente[key];
    }
  }
  return api.put(`/clientes/${id}`, body);
}

// Cria um cliente novo
export function postCliente(cliente) {
  let body = {};
  for (let key in cliente) {
    body[key] = cliente[key];
  }
  return api.post("/clientes/add", body);
}

// Get Images
export async function getImages(id) {
  const response = await api.get(`/clientes/${id}/imgs`);
  return response.data.cliente_imgs;
}

// Upload Image
export async function uploadImage(fd) {
  const response = await api.post("/clientes/uploadImage", fd);
  return response;
}
