<template>
  <v-container v-if="vueAddCliente">
    <v-row>
      <v-col cols="12">
        <PageToolbar title="Novo Cliente" icon="assignment_ind" dark />
      </v-col>

      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title class="subtitle-1">Dados Gerais</v-card-title>
          <v-card-text>
            <v-row>
              <!-- Nome -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.nome"
                  label="Nome"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Adm Id -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.adm_id"
                  label="Adm Id"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Documento -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.documento"
                  label="Documento"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Status -->
              <v-col cols="12" md="6">
                <v-card
                  flat
                  style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                  height="56px"
                  class="pa-3 d-flex justify-space-between align-center"
                >
                  <v-subheader
                    style="height: auto !important"
                    class="ma-0 pa-0 body-1"
                    >Status</v-subheader
                  >

                  <v-switch
                    class="ma-0 pa-0"
                    hide-details
                    :false-value="0"
                    :true-value="1"
                    v-model="cliente.status"
                  ></v-switch>
                </v-card>
              </v-col>

              <!-- Endereco -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.endereco"
                  label="Endereco"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- celular -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.celular"
                  label="Celular"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- telefone -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.telefone"
                  label="Telefone"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- localizacao -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="cliente.localizacao"
                  label="Localizacao"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Cidade -->
              <v-col cols="12" md="6">
                <v-select
                  v-model.number="cliente.cidade_id"
                  :items="cidades"
                  clearable
                  item-text="nome"
                  item-value="id"
                  dense
                  filled
                  hide-details
                  label="Cidades"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      @click="createCliente"
      :disabled="!valid"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>save</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { fetchCidades } from "@/services/api/cidades.api.js";
import { postCliente } from "@/services/api/clientes.api.js";
import { mapGetters } from "vuex";

export default {
  name: "NovoCliente",
  data: () => ({
    cliente: {},
    cidades: [],
    loading: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueAddCliente() {
      return this.getAccess("vueCliente", "adicionar");
    },
    valid() {
      let valid = false;
      if (
        this.cliente.documento &&
        this.cliente.nome &&
        this.cliente.adm_id != null &&
        this.cliente.cidade_id
      ) {
        valid = true;
      }
      return valid;
    },
  },
  methods: {
    getCidades() {
      fetchCidades()
        .then((response) => {
          this.cidades = response;
        })
        .catch((error) => console.log(error));
    },
    createCliente() {
      this.loading = true;
      postCliente(this.cliente)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Cliente criado com sucesso!");
            this.cliente = {};
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.vueAddCliente) {
      this.getCidades();
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
